import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withPhaseItemsProvider } from 'providers/PhaseItemsProvider';
import PhaseHeader from './PhaseHeader';
import PhaseItems from './PhaseItems';
import useOnDrop from './PhaseItems/useOnDrop';
import './PhasePanel.scss';
import ProjectPhase from 'model/ProjectPhase';
import TemplateSection from './TemplateSection';
import { useCurrentProject } from 'providers/ProjectProvider';
import ExportBriefingButton from './ExportBriefingButton';
import ArrowIcon from '../icons/Arrow';

const PhasePanel = ({
  phase,
  presence,
  onAddLocations,
  onAddMultipleLocations,
  onASPImport,
  onAddService,
  onAddMetro,
  onAddEdge,
  onClose,
  loadingPhase,
  collapseSidebar,
  isSidebarCollapsed,
  selectedItem,
  onExportBriefing,
  isAspInfoOpen,
  projectId,
  onSelectionChanged,
  template,
  mapConfig,
  companyId,
  isDSPAllowed,
}) => {
  const onDrop = useOnDrop({ selectedItem, onClose });

  const _renderEmptyPhase = () => {
    return (
      <div className="phase-empty">
        <p>Start by adding the customer's sites and services</p>
      </div>
    );
  };

  const { isEditing, isTemplate } = useCurrentProject();

  return (
    <div className={classnames('phase-panel', { isSidebarCollapsed })}>
      {loadingPhase ? (
        <div className={'loading-wrapper'}>
          <div className={'loading'}></div>
          <p className={'loading-message'}>Loading phase...</p>
        </div>
      ) : (
        <>
          <button className="collapse-button" onClick={collapseSidebar}>
            <ArrowIcon className={'rotate180-icon'} color={'#FFFFFF'} width={8} height={10} />
          </button>
          {template && <TemplateSection companyId={companyId} template={template} onClose={onClose} mapConfig={mapConfig} />}
          {(!isTemplate || isEditing) && (
            <PhaseHeader
              phase={phase}
              presence={presence}
              onAddLocations={onAddLocations}
              onAddMultipleLocations={onAddMultipleLocations}
              onASPImport={onASPImport}
              onAddService={onAddService}
              onAddMetro={onAddMetro}
              onAddEdge={onAddEdge}
              onDropped={onDrop}
              isDSPAllowed={isDSPAllowed}
            />
          )}
          {isEditing && phase.isEmpty ? (
            _renderEmptyPhase()
          ) : (
            <PhaseItems
              phase={phase}
              selectedItem={selectedItem}
              isAspInfoOpen={isAspInfoOpen}
              projectId={projectId}
              onSelectionChanged={onSelectionChanged}
              onClose={onClose}
            />
          )}
          {!isTemplate && <ExportBriefingButton phase={phase} onExportBriefing={onExportBriefing} />}
        </>
      )}
    </div>
  );
};

PhasePanel.propTypes = {
  projectId: PropTypes.string,
  phase: PropTypes.instanceOf(ProjectPhase).isRequired,
  template: PropTypes.object, //TODO: change to instanceOf(Template)
  onAddLocations: PropTypes.func.isRequired,
  onAddMultipleLocations: PropTypes.func.isRequired,
  onAddService: PropTypes.func.isRequired,
  onAddMetro: PropTypes.func.isRequired,
  onExportBriefing: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
  isAspInfoOpen: PropTypes.bool,
};

export default withPhaseItemsProvider(PhasePanel);
