import React, { useState } from 'react';
import { DropTarget } from 'react-dnd';
import { target, dropCollect } from './dndSpecs';
import { withActivityMonitor } from '../../../activity';
import cn from 'classnames';
import { Menu, MenuButton, MenuHeader, MenuItem } from '@szhsin/react-menu';
import PlusIcon from '../icons/Plus';
import UserIcon from '../icons/User';
import StarIcon from '../icons/Star';
import DocumentIcon from '../icons/Document';
import MetroIcon from '../icons/Metro';
import EdgeIcon from '../icons/Edge';
import HandshakeIcon from '../icons/Handshake';

function DisconnectDraggingOverlay(props) {
  const { empty } = props;
  return (
    <div className={`disconnect-overlay dragging ${empty ? 'empty' : ''}`}>
      <p>Disconnect</p>
    </div>
  );
}
export function DisconnectHoverOverlay(props) {
  const { empty } = props;
  return (
    <div className={`disconnect-overlay hover ${empty ? 'empty' : ''}`}>
      <p>Disconnect</p>
    </div>
  );
}

const PhaseHeader = ({
  phase,
  onAddLocations,
  onAddMultipleLocations,
  onASPImport,
  onAddService,
  onAddMetro,
  onAddEdge,
  dragInProgress,
  isOver,
  connectDropTarget,
  isDSPAllowed,
}) => {
  const [open, setOpen] = useState(false);

  const onMenuChange = (open) => {
    setOpen(open);
  };

  let overlay = null;
  if (phase.unconnectedItems.length < 1) {
    if (dragInProgress) {
      overlay = <DisconnectDraggingOverlay />;
    }
    if (isOver) {
      overlay = <DisconnectHoverOverlay />;
    }
  }
  return connectDropTarget(
    <div className="phase-header">
      <div className={cn('add-products', { open })}>
        <Menu
          onMenuChange={({ open }) => onMenuChange(open)}
          initialMounted
          align={'start'}
          className={'default-menu add-products-menu'}
          menuClassName={'add-products-menu__menu'}
          menuButton={
            <MenuButton className={'menu-btn'}>
              <>
                <span className={'text'}>Add locations & products...</span>
                <div className="create-node">
                  <PlusIcon width={11} height={11} />
                </div>
              </>
            </MenuButton>
          }>
          <MenuHeader>Client Locations</MenuHeader>
          <MenuItem onClick={onAddLocations}>
            <UserIcon width={14} height={15} /> Add Customer Sites
          </MenuItem>
          {isDSPAllowed && (
            <MenuItem onClick={onASPImport}>
              <StarIcon width={16} height={16} rounded /> Import Sites via DSP
            </MenuItem>
          )}
          <MenuItem onClick={onAddMultipleLocations}>
            <DocumentIcon width={12} height={15} /> Upload Sites via XLSX
          </MenuItem>
          <MenuHeader>Platform Equinix</MenuHeader>
          <MenuItem onClick={onAddMetro}>
            <MetroIcon width={14} height={15} /> Add Equinix Metros
          </MenuItem>
          <MenuItem onClick={onAddEdge}>
            <EdgeIcon width={16} height={15} /> Add Network Edge
          </MenuItem>
          <MenuItem onClick={onAddService}>
            <HandshakeIcon width={22} height={19} /> Add a Service
          </MenuItem>
        </Menu>
      </div>
      {overlay}
    </div>
  );
};

export default withActivityMonitor(DropTarget('projectItem', target, dropCollect)(PhaseHeader));
