import React, { useState } from 'react';
import classnames from 'classnames';
import TriangleIcon from 'views/components/icons/Triangle';
import Expandable from '../../UseCases/Expandable';
import ToggleButton from 'views/components/ToggleButton';
import TrashIcon from 'views/components/icons/Trash';
import EyeOnIcon from 'views/components/icons/EyeOn';
import EyeOffIcon from 'views/components/icons/EyeOff';
import DeploymentSelect from './DeploymentSelect';
import { DEPLOYMENT_TYPES } from 'model/deploymentTypes';

const ExpandableHeader = ({
  children,
  infraComponent,
  setInfraComponentState,
  initOpen = false,
  isExpandable,
  onToggled,
  onDelete,
  enableInfraComponent,
  networkEdgeAvailable,
}) => {
  const canExpandDevices = isExpandable && infraComponent.isEnabled;
  const [showAllDeployments, setShowAllDeployments] = useState(false);

  const showInfraComponentActions = () => {
    return (
      <>
        {(infraComponent.isCustom || infraComponent.isEnabled) && (
          <div
            data-testId="visibility-icon"
            className={classnames('clickable icon')}
            onClick={() => setInfraComponentState({ ...infraComponent, isVisible: !infraComponent.isVisible })}>
            {infraComponent.isVisible ? <EyeOnIcon width={16} height={16} color="black" /> : <EyeOffIcon width={16} height={16} color="black" />}
          </div>
        )}
        {infraComponent.isCustom ? (
          <div className="clickable icon infraComponent-trash" data-testId="delete-icon" onClick={onDelete}>
            <TrashIcon width={13} height={14} color="#333333" />
          </div>
        ) : (
          <ToggleButton small className="toggle" onToggled={onToggled} checked={infraComponent.isEnabled} />
        )}
      </>
    );
  };

  const Tooltip = ({ text, children }) => {
    return (
      <div className="tooltip" data-testId="deployment-tooltip">
        <span className="tooltiptext">{text}</span>
        {children}
      </div>
    );
  };

  return (
    <Expandable initOpen={initOpen}>
      {(open, toggle) => {
        return (
          <div data-testId="infraComponent-item" className={classnames({ open, closed: !open && canExpandDevices })}>
            <div className="column" data-testId={infraComponent.id}>
              <div className="item first container">
                <button className={`expand-content infraComponent-information`} onClick={toggle} disabled={!canExpandDevices}>
                  <p title={infraComponent.name}>{infraComponent.name}</p>
                </button>
                {canExpandDevices && (
                  <div onClick={toggle} className="open-icon infraComponent-icon" data-testId={'open-icon'}>
                    <TriangleIcon width={3} height={3} color="black" viewBox="0 0 3 3" />
                  </div>
                )}
              </div>
              <div className="item last infraComponent-item">
                {!infraComponent.isCustom && !infraComponent.isEnabled && (
                  <div className="icon-container" onMouseEnter={() => setShowAllDeployments(true)} onMouseLeave={() => setShowAllDeployments(false)}>
                    <div className={classnames({ otherIcons: true, show: showAllDeployments })}>
                      {showAllDeployments &&
                        infraComponent.allowedDeployments.slice(1).map((deployment) => (
                          <Tooltip key={deployment} text={DEPLOYMENT_TYPES[deployment].label}>
                            {DEPLOYMENT_TYPES[deployment].icon('black', 16, 16)}
                          </Tooltip>
                        ))}
                    </div>
                    <Tooltip text={`${DEPLOYMENT_TYPES[infraComponent.allowedDeployments[0]].label} (Default)`}>
                      {DEPLOYMENT_TYPES[infraComponent.allowedDeployments[0]].icon('black', 16, 16)}
                    </Tooltip>
                  </div>
                )}
                {infraComponent.isEnabled && (
                  <DeploymentSelect
                    enableInfraComponent={enableInfraComponent}
                    infraComponent={infraComponent}
                    setInfraComponentState={setInfraComponentState}
                    networkEdgeAvailable={networkEdgeAvailable}
                  />
                )}
                {showInfraComponentActions()}
              </div>
            </div>
            {isExpandable && open && children}
          </div>
        );
      }}
    </Expandable>
  );
};

export default ExpandableHeader;
