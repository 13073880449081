import React, { useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import ContextMenu from '../ContextMenu';

const PlatformMarker = ({ metro, loading, getIcon, iconSize = 4, product, platform, onSelect, onEnable, onDisable, isEditing }) => {
  const [contextMenu, setContextMenu] = useState(false);

  const isEnabled = () => {
    if (platform === 'metal') {
      return !product?.value ? metro.metalEnabled : metro.metalPlans?.some((metal) => metal.id.value === product.value);
    }

    if (platform === 'edge') {
      return !product?.value ? metro.networkEdgeEnabled : metro.networkEdgeCategories?.some((category) => category.id.value === product.value);
    }
  };

  const productName = platform === 'metal' ? '' : 'Network Edge';

  const enabled = isEnabled();

  const _onEnable = async () => {
    setContextMenu(false);
    onEnable(metro.code, product.value || null);
  };

  const _onDisable = async () => {
    setContextMenu(false);
    onDisable(metro.code, product.value || null);
  };

  const _onClick = () => {
    return enabled ? onSelect(metro) : _onEnable();
  };

  const _onContextMenu = () => {
    if (!isEditing) return;
    enabled && setContextMenu(true);
  };

  const tooltipOffset = [0, iconSize < 0.7 ? -12 : -25];

  return (
    <React.Fragment>
      <Marker
        weight={10000}
        position={metro.coordinates.toLeaflet()}
        icon={getIcon(enabled, iconSize)}
        onClick={_onClick}
        onContextMenu={_onContextMenu}
        opacity={loading ? 0.8 : 1}
        zIndexOffset={600}
        className="platform-marker zindexed">
        <Tooltip key={`${metro.code}-${iconSize}-PM`} pane={'tooltipPane'} direction="top" offset={tooltipOffset}>
          {metro.name}
        </Tooltip>

        {contextMenu && (
          <ContextMenu onClose={() => setContextMenu(false)}>
            <button onClick={_onDisable}>Disable {productName}</button>
          </ContextMenu>
        )}
      </Marker>
    </React.Fragment>
  );
};

export default PlatformMarker;
