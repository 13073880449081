export const FILTER = Object.freeze({
  ALL_CATEGORIES: '',
  AMER: 'AMER',
  APAC: 'APAC',
  EMEA: 'EMEA',
  EDGE: 'EDGE',
  FABRIC: 'FABRIC',
  CLOUD: 'CLOUD',
  CONTENT: 'CONTENT',
  ENTERPRISE: 'ENTERPRISE',
  FINANCIAL: 'FINANCIAL',
  NETWORK: 'NETWORK',
});

export const FILTER_OPTIONS = Object.freeze({
  [FILTER.ALL_CATEGORIES]: {
    label: 'All categories',
    value: FILTER.ALL_CATEGORIES,
  },
  [FILTER.AMER]: {
    label: 'AMER',
    value: FILTER.AMER,
  },
  [FILTER.APAC]: {
    label: 'APAC',
    value: FILTER.APAC,
  },
  [FILTER.EMEA]: {
    label: 'EMEA',
    value: FILTER.EMEA,
  },
  [FILTER.EDGE]: {
    label: 'Network Edge',
    value: FILTER.EDGE,
  },
  [FILTER.FABRIC]: {
    label: 'Equinix Fabric',
    value: FILTER.FABRIC,
  },
  [FILTER.CLOUD]: {
    label: 'Cloud & IT Services',
    value: FILTER.CLOUD,
  },
  [FILTER.CONTENT]: {
    label: 'Content & Digital Media',
    value: FILTER.CONTENT,
  },
  [FILTER.ENTERPRISE]: {
    label: 'Enterprise',
    value: FILTER.ENTERPRISE,
  },
  [FILTER.FINANCIAL]: {
    label: 'Financial Services',
    value: FILTER.FINANCIAL,
  },
  [FILTER.NETWORK]: {
    label: 'Network',
    value: FILTER.NETWORK,
  },
});

export const SORT = Object.freeze({
  TIMES_SAVED: 'TIMES_SAVED',
  TIMES_USED: 'TIMES_USED',
  UPDATED_DATE: 'UPDATED_DATE',
  CREATED_DATE: 'CREATED_DATE',
});

export const SORT_OPTIONS = Object.freeze({
  [SORT.TIMES_SAVED]: {
    label: 'Times Saved',
    value: SORT.TIMES_SAVED,
  },
  [SORT.TIMES_USED]: {
    label: 'Times Used',
    value: SORT.TIMES_USED,
  },
  [SORT.UPDATED_DATE]: {
    label: 'Updated Date',
    value: SORT.UPDATED_DATE,
  },
  [SORT.CREATED_DATE]: {
    label: 'Created Date',
    value: SORT.CREATED_DATE,
  },
});

export const DIRECTION = Object.freeze({
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
});

export const formatCategoriesForSelect = (categories) => {
  const filterOptions = Object.values(FILTER_OPTIONS);
  return filterOptions.filter((option) => categories?.includes(option.label));
};

export const formatCategories = (categories) => categories?.map((category) => category.label);
