import React from 'react';
import NewEdgeIcon from 'views/components/icons/NewEdge';
import ColocationIcon from 'views/components/icons/Colocation';
import FabricIcon from 'views/components/icons/Fabric';
import FabricRouterIcon from 'views/components/icons/FabricRouter';
import EquinixInternetAccessIcon from 'views/components/icons/EquinixInternetAccess';
import EquinixPlatformIcon from 'views/components/icons/EquinixPlatform';

const generateIcon = (IconComponent, color, width, height, viewBox) => {
  return <IconComponent color={color} height={height} width={width} viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`} />;
};
//On update also update deployments.js in reports
export const DEPLOYMENT_TYPES = {
  COLOCATION: {
    label: 'Colocation',
    value: 'COLOCATION',
    icon: (color, width, height) => generateIcon(ColocationIcon, color, width, height),
  },
  EDGE: {
    label: 'Network Edge',
    value: 'EDGE',
    icon: (color, width, height) => generateIcon(NewEdgeIcon, color, width, height, '0 0 11 12.4667'),
  },
  FABRIC: {
    label: 'Equinix Fabric',
    value: 'FABRIC',
    icon: (color, width, height) => generateIcon(FabricIcon, color, width, height),
  },
  ROUTER: {
    label: 'Fabric Cloud Router',
    value: 'ROUTER',
    icon: (color, width, height) => generateIcon(FabricRouterIcon, color, width, height),
  },
  INTERNET: {
    label: 'Equinix Internet Access',
    value: 'INTERNET',
    icon: (color, width, height) => generateIcon(EquinixInternetAccessIcon, color, width, height),
  },
  MANAGEMENT: {
    label: 'Management',
    value: 'MANAGEMENT',
    icon: (color, width, height) => generateIcon(EquinixPlatformIcon, color, width, height),
  },
};

export const MENU_OPTIONS = [
  DEPLOYMENT_TYPES.COLOCATION,
  DEPLOYMENT_TYPES.EDGE,
  DEPLOYMENT_TYPES.FABRIC,
  DEPLOYMENT_TYPES.ROUTER,
  DEPLOYMENT_TYPES.INTERNET,
  DEPLOYMENT_TYPES.MANAGEMENT,
];

// We can keep it just in case we need it in the future

// const filterMetalAndEdgeAvailable = (metalAvailable, networkEdgeAvailable) => {
//   let deploymentTypesFiltered = MENU_OPTIONS;
//   if (!metalAvailable) {
//     deploymentTypesFiltered = deploymentTypesFiltered.filter((item) => item.value !== 'METAL');
//   }

//   if (!networkEdgeAvailable) {
//     deploymentTypesFiltered = deploymentTypesFiltered.filter((item) => item.value !== 'EDGE');
//   }

//   return deploymentTypesFiltered;
// };

export const filterDeploymentTypes = (allowedDeploymentTypes) => {
  if (allowedDeploymentTypes.length === 0) {
    return MENU_OPTIONS.filter((item) => item.value === 'COLOCATION');
  } else {
    return MENU_OPTIONS.filter((item) => allowedDeploymentTypes.includes(item.value));
  }
};

export const filterDeploymentType = (deployment) => {
  return MENU_OPTIONS.find((item) => item.value === deployment);
};
